import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BackBTn, EmailIcon, LockIcon } from "../elements/SvgElements";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { AuthApi } from "../config/axiosUtils";
import { toast } from "react-toastify";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLoading } from "../features/loadingHooks";
// import useValidEmail from "../services/useValidEmail";
// import { CgCloseO } from "react-icons/cg";
import FighterSingUpSuccess from "../components/CustomLoader/FighterSingUpSuccess";
const FighterSignup = () => {
  const { globalLoading, startGloablLoading, stopGlobalLoading } = useLoading();
  const [siginError, setSiginError] = useState("");
  const [showSucess, setShowSucess] = useState({
    show: false,
    email: ""
  });
  
  // const [data, _, __, validEmail] = useValidEmail();

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
  } = useForm();

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const { confirmpasswordref, confirmpassword } = {
    ...register("confirmpassword", {
      required: {
        value: true,
        message: "Confirm Password Is Required",
      },

      validate: (val) => {
        console.log("val", val);

        if (watch("password") !== val) {
          return "Your passwords do not match";
        }
      },
    }),
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  function hanelSignup(formData) {
    setSiginError("");
    // if (data !== "DELIVERABLE" && data !== "PASS") {
    //   toast.error("Enter a valid Email");
    //   return;
    // }
    formData["role"] = "fighter";
    startGloablLoading();

    AuthApi.signup(formData)
      .then((res) => {
        toast.success(res?.data?.message);
        setShowSucess({show: true, email: formData.email});
      })
      .catch((e) => {
        let error;
        if (e?.code) {
          error = e?.message;
        }
        if (e?.response?.status === 422) {
          const validationErrors = e.response?.data?.data || [];
          error = validationErrors[0]?.msg;
        }

        if (e?.response?.status === 500) {
          return (error = e?.response?.data?.message);
        }

        setSiginError(error);
      })
      .finally(() => {
        stopGlobalLoading();
      });
  }



    // Handle autofill for email, password, and confirmPassword
    useEffect(() => {
      setTimeout(() => {
        const email = document.querySelector('input[name="email"]');
        const password = document.querySelector('input[name="password"]');
        const confirmPassword = document.querySelector('input[name="confirmpassword"]');
  
        if (email && email.value) setValue("email", email.value);
        if (password && password.value) setValue("password", password.value);
        if (confirmPassword && confirmPassword.value) setValue("confirmpassword", confirmPassword.value);
      }, 500); // Adjust this delay if needed
    }, [setValue]);

  return (
    <>
      <div className="form-start-tab">
        <div className="form-brand-ct">
          <span className="back-i-btn">
            <button
              className="backbtn"
              type="button"
              //   onClick={handleBackButtonClick}
            >
              <Link to="/landing">{BackBTn}</Link>
            </button>
          </span>
          <div className="m-auto w-60">
            <ProgressBar now={30} variant="black" />
          </div>
          {/* sign in content start from here  */}
          <div className="login-view mt-4 text-left">
            <h2 className="sub-head">Create account </h2>
            <p className="para mt-2 text-left">
              Please enter your email & password to sign up.
            </p>
          </div>
          {/* ... signin content form */}
          <form
            className="user-login-form"
            onSubmit={handleSubmit(hanelSignup)}
            id="signup-btn"
          >
            <div className="form-start-login">
              <div className="form-brand">
                {/* form start from here */}
                <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                  {/* form input one for log email */}
                  <div className="form-group text-left">
                    <label className="form-head mb-2" htmlFor="log-mail">
                      Email
                    </label>
                    <div
                      className={`inputWithIcon email-input ${
                        errors?.email ? "error-border" : ""
                      } `}
                    >
                      <input
                        type="text"
                        id="login-email"
                        className={`${
                          errors?.email ? "error-placeholder" : ""
                        }`}
                        placeholder="Enter your email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a Valid Email",
                          },
                          // onChange: (e) => {
                          //   validEmail(e.target.value);
                          // },
                        })}
                      />

                      {/* {data === "DELIVERABLE" || data === "PASS" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          style={{
                            marginRight: "0.5rem",
                          }}
                        >
                          <mask
                            id="mask0_1899_3470"
                            maskUnits="userSpaceOnUse"
                            x="1"
                            y="0"
                            width="19"
                            height="20"
                          >
                            <path
                              d="M10.7015 18.3334C11.7961 18.3348 12.8801 18.1199 13.8913 17.7011C14.9026 17.2822 15.8211 16.6676 16.594 15.8926C17.369 15.1196 17.9836 14.2012 18.4025 13.1899C18.8214 12.1787 19.0363 11.0946 19.0348 10.0001C19.0362 8.90554 18.8213 7.8215 18.4024 6.81027C17.9836 5.79904 17.369 4.88055 16.594 4.10759C15.8211 3.3326 14.9026 2.718 13.8913 2.29912C12.8801 1.88024 11.7961 1.66534 10.7015 1.66676C9.60695 1.66536 8.52291 1.88028 7.51168 2.29916C6.50045 2.71804 5.58197 3.33262 4.809 4.10759C4.03403 4.88055 3.41945 5.79904 3.00057 6.81027C2.58169 7.8215 2.36678 8.90554 2.36817 10.0001C2.36675 11.0946 2.58166 12.1787 3.00054 13.1899C3.41942 14.2012 4.03402 15.1196 4.809 15.8926C5.58197 16.6676 6.50045 17.2821 7.51168 17.701C8.52291 18.1199 9.60695 18.3348 10.7015 18.3334Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="1.66667"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.36816 10L9.86816 12.5L14.8682 7.5"
                              stroke="black"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </mask>
                          <g mask="url(#mask0_1899_3470)">
                            <path
                              d="M0.70166 0H20.7017V20H0.70166V0Z"
                              fill="#212121"
                            />
                          </g>
                        </svg>
                      ) : (
                        <CgCloseO
                          size={25}
                          color="red"
                          style={{
                            marginRight: "0.5rem",
                          }}
                        />
                      )} */}

                      {EmailIcon}
                    </div>
                    {errors?.email && (
                      <small className="error-message">
                        {errors?.email?.message}
                      </small>
                    )}
                  </div>
                  {/* form input two for log password */}
                  <div className="form-group text-left mt-2">
                    <label className="form-head mb-2" htmlFor="password">
                      Password
                    </label>
                    <div
                      className={`password-input ${
                        errors?.password ? "error-border" : ""
                      }`}
                    >
                      {LockIcon}
                      <input
                        type={showPassword?.password ? "text" : "password"}
                        id="password"
                        className={`${
                          !showPassword.password &&
                          watch("password")?.length > 0
                            ? "if-password"
                            : null
                        }`}
                        placeholder="Enter your password "
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Password is Required",
                          },
                          minLength: {
                            value: 6,
                            message:
                              "Password Should be More Than 6 Characters",
                          },
                        })}
                      />
                      {watch("password")?.length > 0 && (
                        <button
                          type="button"
                          className="showHideIcon"
                          onClick={() => togglePasswordVisibility("password")}
                        >
                          {showPassword?.password ? (
                            <FaEye color="#9E9E9E" size={20} />
                          ) : (
                            <FaEyeSlash color="#9E9E9E" size={20} />
                          )}
                        </button>
                      )}
                    </div>
                    {errors?.password && (
                      <small className="error-message">
                        {errors?.password?.message}
                      </small>
                    )}
                  </div>

                  {/* re-enter password start */}
                  <div className="form-group text-left mt-2">
                    <label className="form-head mb-2" htmlFor="confirmpassword">
                      {/* re-enter password end */}
                      Re-enter Password
                    </label>
                    <div
                      className={`password-input ${
                        errors?.confirmpassword ? "error-border" : ""
                      }`}
                    >
                      {LockIcon}
                      <input
                        type={
                          showPassword?.confirmPassword ? "text" : "password"
                        }
                        id="confirmpassword"
                        className={`${
                          !showPassword?.confirmPassword &&
                          watch("confirmpassword")?.length > 0
                            ? "if-password"
                            : ""
                        }`}
                        placeholder="Confirm your password"
                        ref={confirmpasswordref}
                        name={confirmpassword}
                        onChange={(e) => {
                          setValue("confirmpassword", e.target.value);
                          trigger("confirmpassword");
                        }}
                      />
                      {watch("confirmpassword")?.length > 0 && (
                        <button
                          type="button"
                          className="showHideIcon"
                          onClick={() =>
                            togglePasswordVisibility("confirmPassword")
                          }
                        >
                          {showPassword?.confirmPassword ? (
                            <FaEye color="#9E9E9E" size={20} />
                          ) : (
                            <FaEyeSlash color="#9E9E9E" size={20} />
                          )}
                        </button>
                      )}
                    </div>
                    {errors?.confirmpassword && (
                      <small className="error-message">
                        {errors?.confirmpassword?.message}
                      </small>
                    )}
                  </div>

                  <div className="term-policy">
                    <input
                      type="checkbox"
                      name="termCondition"
                      id="termCondition"
                      {...register("agreeTermConditions", {
                        required: {
                          value: true,
                          message: "You must agree to the Terms & Policy in order to sign up.",
                        },
                      })}
                    />
                    <label htmlFor="termCondition">
                      I agree to Fight Companion{" "}
                      <Link className="text-black" to={`/terms-of-services`}>
                        Terms
                      </Link>{" "}
                      &
                      <Link className="text-dark" to={`/privacy-policy`}>
                        {" "}
                        Policy
                      </Link>{" "}
                    </label>
                    {errors?.agreeTermConditions && (
                      <small className="error-message">
                        {errors?.agreeTermConditions?.message}
                      </small>
                    )}
                  </div>

                  <div className="already-have-account">
                    <label htmlFor="">Already have an account?</label>
                    <Link
                      className="text-decoration-none text-dark"
                      to="/signin"
                    >
                      Sign in
                    </Link>
                  </div>

                  <div className="account-switch">
                    {/* btn-for -sign-in */}
                    {/* <Link to="fighterhome"> */}
                    <button
                      type="submit"
                      className={`long-btn-sign-in mt-2 mx-auto ${
                        globalLoading ? "long-btn-sign-in-disabled" : null
                      }`}
                      disabled={globalLoading}
                    >
                      {" "}
                      {!globalLoading ? "Sign up" : "loading..."}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <span className="text-center d-block">
            <strong className="text-danger">{siginError && siginError}</strong>
          </span>
        </div>
      </div>

      {showSucess?.show && <FighterSingUpSuccess email={showSucess.email} />}

    </>
  );
};

export default FighterSignup;
